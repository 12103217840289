<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">예약일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    format="YYYY-MM-DD"
                    type="lookup-condition"
                    v-model="searchConditions.resveDate"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      new Date(searchConditions.resveDate)
                    ),
                  }"
              >{{
                  getDayOfWeekCaption(new Date(searchConditions.resveDate))
                }}</span
              >)
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getWeekdayWeekendCaptionColor(
                      new Date(searchConditions.resveDate)
                    ),
                  }"
              >{{
                  getWeekdayWeekendCaption(
                      new Date(searchConditions.resveDate),
                  )
                }}</span
              >)
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">예약코스</div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                    v-model="searchConditions.resveCourseList"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.resveCourseOptions"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="240"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">예약자명</div>
            <ul class="content">
              <div class="item">
               <input-text
                   v-model="searchConditions.resveName"
                   @keydown.enter.native="onReservationGuestBookViewClicked"
               />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">예약시간</div>
            <ul class="content">
              <div class="item time">
                <input-time
                    ref="resveTimeFrom"
                    v-model="searchConditions.resveTimeFrom"
                    format="HH:mm"
                />
              </div>
              <div class="item text">~</div>
              <div class="item time">
                <input-time
                    ref="resveTimeTo"
                    v-model="searchConditions.resveTimeTo"
                    format="HH:mm"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="Horizontal"
                          v-model="isHorizontal"
                          name="isHorizontal"
                      />
                      <i></i>
                      <div class="label">가로</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="Vertical"
                          v-model="isHorizontal"
                          name="isHorizontal"
                      />
                      <i></i>
                      <div class="label">세로</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="Course"
                          v-model="sort"
                          name="sort"
                      />
                      <i></i>
                      <div class="label">코스</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="Time"
                          v-model="sort"
                          name="sort"
                      />
                      <i></i>
                      <div class="label">시간</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          id="cmpnPrintFlag"
                          v-model="searchConditions.cmpnPrintFlag"
                          value="true"
                      />
                      <i></i>
                      <div class="label">동반자 출력</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          id="emptyFormPrintFlag"
                          v-model="searchConditions.emptyFormPrintFlag"
                          value="true"
                      />
                      <i></i>
                      <div class="label">빈양식 출력</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
<!--          <li class="field">-->
<!--            <ul class="content">-->
<!--              <li class="item">-->
<!--                <ul class="check">-->
<!--                  <li>-->
<!--                    <label>-->
<!--                      <input-->
<!--                          type="checkbox"-->
<!--                          id="bottomLogoFlag"-->
<!--                          v-model="searchConditions.bottomLogoFlag"-->
<!--                          value="true"-->
<!--                      />-->
<!--                      <i></i>-->
<!--                      <div class="label">하단 로고 출력</div>-->
<!--                    </label>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </li>-->
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onReservationGuestBookViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section">
          <div class="section-body">
            <ReportView ref="reportViewComponent" :isPopup="false"/>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
        ref="searchDetailPopup"
        :header="`상세검색`"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="false"
        :visible="false"
        width="680"
        :animationSettings="{ effect: 'None' }"
        v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-reservationGuestBookPrint">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">단체종류</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                          ref="grpKindMultiSelect"
                          cssClass="lookupDetail-condition-multiselect"
                          placeHolder="전체"
                          v-model="searchConditions.grpKindList"
                          :dataSource="searchOptions.grpKindOptions"
                          :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">부구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                          ref="partDivMultiSelect"
                          cssClass="lookupDetail-condition-multiselect"
                          placeHolder="전체"
                          v-model="searchConditions.partDivList"
                          :dataSource="searchOptions.partDivOptions"
                          :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">비고</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                          v-model="searchConditions.resveRemarks"
                          @keydown.native="formPreventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field notPrintTeamGrid">
                  <div class="title">출력 제외할<br/>단체 선택</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-grid-wrapper
                          ref="notPrintTeamGrid"
                          :allow-editing="false"
                          :allow-filtering="false"
                          :allow-resizing="true"
                          :isSelectedRowRetain="false"
                          :provides="grid"
                          :columns="notPrintTeamGridColumn"
                          :dataSource="orgReservationGuestBookInfoList"
                          :selectionSettings="selectionSettings"
                          @queryCellInfo="queryCellInfo"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  @click.native="onReservationGuestBookDetailViewClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onSearchDetailInit"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  :is-icon-custom="true"
                  @click.native="onSearchDetailPopupClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .lookupDetail-condition .title {
  width: 80px;
}

body .lookupDetail-condition .content {
  width: calc(100% - 80px);
}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from '@/views/layout/mixin/commonMixin';
import InputDate from '@/components/common/datetime/InputDate';
import InputTime from '@/components/common/datetime/InputTime';
import {MultiSelect, CheckBoxSelection} from '@syncfusion/ej2-dropdowns';
import {
  Edit,
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
} from '@syncfusion/ej2-vue-grids';

import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import moment from 'moment';

import {
  commonCodesGetCommonCode,
  commonCodesGetComName, commonCodesGetSortNo,
  commonCodesGetStandardInfo,
} from '@/utils/commonCodes';
import {
  getFormattedDate,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from '@/utils/date';
import {formPreventEnterEvent} from '@/utils/formUtil';
// import { orderBy as _orderBy } from "lodash";

import EjsGridWrapper from '@/components/common/EjsGridWrapper';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import InputText from '@/components/common/text/InputText';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {phoneNumberFormatter} from "@/utils/formatter";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: 'ReservationGuestBookPrint',
  components: {
    InputText,
    EjsGridWrapper,
    InputDate,
    InputTime,
    ReportView,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  created() {
    this.searchConditions.resveDate = this.$route.query.bsnDate? this.$route.query.bsnDate : getFormattedDate(new Date());
    this.searchConditions.resveName = this.$route.query.resveName ? this.$route.query.resveName : '';
    this.searchOptions.resveCourseOptions = commonCodesGetCommonCode(
        'COURSE_CODE',
        true,
    );
    this.searchOptions.grpKindOptions = commonCodesGetCommonCode(
        'GRP_KIND',
        true,
    );
    this.searchOptions.partDivOptions = commonCodesGetCommonCode(
        'PART_DIV',
        true,
    );
  },
  mounted() {
    this.onReservationGuestBookViewClicked();
  },
  data() {
    return {
      grid: [Edit, Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
      selectionSettings: {type: 'Multiple', mode: 'Both', enableToggle: true},
      searchConditions: {
        resveDate: null,
        resveCourseList: [],
        resveTimeFrom: '00:00',
        resveTimeTo: '23:59',
        grpKindList: [],
        partDivList: [],
        resveRemarks: null,
        timeIdList: [],
        resveName:'',
        cmpnPrintFlag: commonCodesGetStandardInfo("noneChkinUseFlag"),
        emptyFormPrintFlag: false,
        // bottomLogoFlag: false,
      },
      sort: 'Course',
      isHorizontal: 'Horizontal',
      commonCodeFields: {text: 'comName', value: 'comCode'},
      searchOptions: {
        resveCourseOptions: null,
        grpKindOptions: null,
        partDivOptions: null,
      },
      isSearchDetailPopupOpen: false,
      reservationGuestBookInfoList: [],
      orgReservationGuestBookInfoList: [],
      notPrintTeamGridColumn: [
        {
          field: 'timeId',
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: 'partDiv',
          headerText: '부구분',
          groupCode: 'PART_DIV',
          width: 60,
          allowEditing: false,
          isCommonCodeField: true,
          textAlign: "center",
        },
        {
          field: 'resveCourse',
          headerText: '코스',
          groupCode: 'COURSE_CODE',
          width: 80,
          allowEditing: false,
          isCommonCodeField: true,
        },
        {
          field: 'resveTime',
          headerText: '시간',
          width: 50,
          allowEditing: false,
          textAlign: "center",
        },
        {
          field: 'holeDiv',
          headerText: '홀',
          groupCode: 'HOLE_DIV',
          width: 50,
          allowEditing: false,
          isCommonCodeField: true,
          textAlign: "center",
        },
        {
          field: 'resveName',
          headerText: '예약자',
          width: 80,
          allowEditing: false,
        },
        {
          field: 'grpName',
          headerText: '단체명',
          width: 90,
          allowEditing: false,
        },
        {
          field: 'deleteFlag',
          headerText: '제외',
          editType: 'booleanedit',
          type: 'boolean',
          displayAsCheckBox: true,
          textAlign: 'center',
          minWidth: 16,
          width: 60,
          allowEditing: true,
          isSelectAllColumn: true,
        },
      ],
    };
  },
  computed: {
    isPopupOpened() {
      return this.isSearchDetailPopupOpen;
    },
  },
  methods: {
    formPreventEnterEvent,
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getWeekdayWeekendCaption,
    getWeekdayWeekendCaptionColor,
    commonCodesGetComName,
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onSearchDetailPopupClose() {
      this.searchConditions.timeIdList = [];
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onSearchDetailInit() {
      this.searchConditions.grpKindList = [];
      this.searchConditions.partDivList = [];
      this.searchConditions.resveRemarks = null;
      this.searchConditions.timeIdList = [];
      this.orgReservationGuestBookInfoList.map(item => {
        item.deleteFlag = false;
        return item;
      });

      this.$refs.notPrintTeamGrid.clearSelection();
    },
    onReservationGuestBookDetailViewClicked() {
      const currentViewRecords = this.$refs.notPrintTeamGrid.getBatchCurrentViewRecords();
      this.searchConditions.timeIdList = currentViewRecords.filter(item => item.deleteFlag).map(item => item.timeId);
      this.onReservationGuestBookViewClicked();
    },
    async onReservationGuestBookViewClicked() {
      // TODO : 검색 조건에 대한 validation 적용해야함.

      if (!this.searchConditions.resveDate) {
        return this.errorToast(
            this.$t('main.validationMessage.requiredMessage', ['예약일자']),
        );
      }

      const timeIdList = JSON.parse(JSON.stringify(this.searchConditions.timeIdList));

      let data = Object.assign({}, this.searchConditions);
      data.resveRemarks = data.resveRemarks ? data.resveRemarks : undefined;
      data.timeIdList = [];

      // const orgReservationGuestBookInfoList = _orderBy((await GolfErpAPI.fetchReservationGuestBooks(data)).map((item, index) => {
      //       item.deleteFlag = !!timeIdList.find(i => i === item.timeId);
      //       return {
      //         _rid: index + 1,
      //         ...item,
      //         courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.resveCourse),
      //       };
      //     },
      // ).filter((data)=> this.routeQuery.timeId ? this.routeQuery.timeId == data.timeId : data ), ["resveDate","resveTime","courseCodeSortNo"]) || [];


      const orgReservationGuestBookInfoList = (await GolfErpAPI.fetchReservationGuestBooks(data)).map((item, index) => {
            item.deleteFlag = !!timeIdList.find(i => i === item.timeId);
            return {
              _rid: index + 1,
              ...item,
              courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.resveCourse),
            };
          },
      ).filter((data)=> this.$route.query.timeId ? this.$route.query.timeId == data.timeId : data )
          .sort((a,b)=> this.sort==='Course'? a.resveCourse - b.resveCourse : a.resveTime - b.resveTime );


      this.$router.replace({'query': null}).catch(()=>{});

      this.orgReservationGuestBookInfoList = orgReservationGuestBookInfoList.filter(item => item.grpName);
      this.reservationGuestBookInfoList = orgReservationGuestBookInfoList.filter(
          item => timeIdList.findIndex(i => i === item.timeId) === -1);

      await this.postReport();

      return 0;
    },
    queryCellInfo(args) {
      const field = args.column?.field;
      if (field === 'deleteFlag') {
        args.cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    postReport() {
      let result = JSON.parse(
          JSON.stringify(this.reservationGuestBookInfoList),
      );

      result = result.map((item) => {
        const resveTime = item?.resveTime.split(":");
        if (!this.searchConditions.cmpnPrintFlag) {
          item.cmpnName1 = item.resveName;
          item.cmpnName2 = '';
          item.cmpnName3 = '';
          item.cmpnName4 = '';
          item.cmpnName5 = '';
          item.cmpnContactTel1 = phoneNumberFormatter(item.cmpnContactTel1) ;
          item.cmpnContactTel2 = '';
          item.cmpnContactTel3 = '';
          item.cmpnContactTel4 = '';
          item.cmpnContactTel5 = '';
        } else {
          let countName = 1;
          let countPhone = 1;
          Object.keys(item).map((list)=>{
            if(list.split("cmpnName").length>1){
              item[`cmpnName${countName}`] = item[list];
              countName++;
            } else if (list.split("cmpnContactTel").length>1){
               item[`cmpnContactTel${countPhone}`] = phoneNumberFormatter(item[list]);
              countPhone++;
            }
          });
          if(countName<=5){
            for(countName-1; countName==5 ; countName++) {
              item[`cmpnName${countName}`] = '';
            }
          }
          if(countPhone<=5) {
            for(countPhone-1; countPhone==5 ; countPhone++) {
              item[`cmpnContactTel${countPhone}`] = '';
            }
          }
        }
        item.resveTime = `${resveTime[0]}시  ${resveTime[1]}분`;
        item.resveDate = moment(item.resveDate).format('YYYY년 MM월 DD일');
        item.resveCourse = commonCodesGetComName(
            'COURSE_CODE',
            item.resveCourse,
        );
        return item;
      });

      // const info = sessionStorage.getItem('Standard-Infos');
      // const imageLogoURL = this.searchConditions.bottomLogoFlag && JSON.parse(info).imageLogoURL;

      const reportData = {
        reportJson: {
          jsonData: result.length > 0 && !this.searchConditions.emptyFormPrintFlag
              ? result
              : [{resveName: '          '}], // 빈 양식 출력(예약자명 및 하단 CI)을 위한 폼
          // imageLogoURL,
        },
      };
      console.log('reportData===>',reportData);
      this.$refs.reportViewComponent.postReport(
          reportData,
          this.$options.name + this.isHorizontal,
      );
    },
  },
};
</script>
